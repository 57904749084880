import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const Vue = require('vue');

const {
    onMounted, ref
} = Vue;

// Locatins Map
const locationsMap = {
    name: 'LocationsMap',
    setup() {
        const ready = ref(false);
        const locations = ref(null);
        let map = null;
        const geojson = {
            type: 'FeatureCollection',
            features: [],
        };

        window.initMapBoxGlobal = () => {
            if (ready.value === false) {
                initMapBox(geojson);
            }
        }

        onMounted(async() => {
            axios.get(`${window.location.origin}/wp-json/wp/v2/posts`, {
                params: {
                    _embed: true,
                    _fields: 'title, id, thumb, permalink, acf',
                    per_page: 9999,
                    categories: wp_urls.locationsCat,
                }
            }).then((response) => {
                locations.value = response.data.filter((location) => location.acf.location);
                let bounds = [];

                for (let i = 0; i < locations.value.length; i += 1) {
                    const coordinates = [
                        parseFloat(locations.value[i].acf.location.lng, 10),
                        parseFloat(locations.value[i].acf.location.lat, 10),
                    ];
                    const featuresData = {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates,
                        },
                        properties: {
                            title: locations.value[i].title.rendered,
                            permalink: locations.value[i].permalink,
                            thumb: locations.value[i].thumb,
                            acf: locations.value[i].acf,
                        },
                    };
                    bounds.push(coordinates);
                    geojson.features.push(featuresData);
                }

            }).catch(function (error) {
                // handle error
                console.log(error);
            });
        });

        function initMapBox(geojson) {
            const token = wp_urls.mapBoxToken;
            if (token) {
                mapboxgl.accessToken = token;

                map = new mapboxgl.Map({
                    container: 'locationsMap__container',
                    style: 'mapbox://styles/herrlich-media/cl4qumhi4000p14qrkn6bozj6',
                    center: geojson.features[0].geometry.coordinates,
                    offset: 300,
                    zoom: 14.5
                });

                geojson.features.forEach((marker) => {
                    const el = document.createElement('div');
                    el.className = 'marker';
                    el.innerHTML = '<svg width="26" height="31" viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.7808 20.3787L13 28L5.21922 20.3787C3.6814 18.8724 2.63496 16.9541 2.21108 14.8668C1.78722 12.7795 2.00472 10.6159 2.83636 8.64924C3.66804 6.68255 5.07693 5.00045 6.88598 3.81646C8.69511 2.63242 10.8228 2 13 2C15.1772 2 17.3049 2.63242 19.1141 3.81646C20.9231 5.00045 22.332 6.68255 23.1637 8.64924C23.9953 10.6159 24.2128 12.7795 23.7889 14.8668C23.365 16.9541 22.3187 18.8724 20.7808 20.3787Z" fill="#222222" stroke="white" stroke-width="3"/></svg>';

                    let popupContent = '<a target="_blank" href="';
                    if (marker.properties.acf.locationLink) {
                        popupContent += `${marker.properties.acf.locationLink}">`;
                    } else {
                        popupContent += `${marker.properties.permalink}">`;
                    }

                    popupContent += `<div class="location" style="background-image: url(&quot;${marker.properties.thumb.url}&quot;); display: block;">`;
                    popupContent += `<h5>${marker.properties.title}</h5>`;

                        if (marker.properties.acf.locationLink) {
                            popupContent += '<p><span>zur Website</span></p>';
                        } else {
                            popupContent += '<p><span>Mehr erfahren</span></p>';
                        }
                        popupContent += '</div>';
                    popupContent += '</a>';


                    new mapboxgl.Marker({
                            element: el,
                            offset: [0, -22],
                        })
                        .setLngLat(marker.geometry.coordinates)
                        .setPopup(new mapboxgl.Popup({ offset: 35 }) // add popups
                            .setHTML(popupContent))
                        .addTo(map);
                }); 

                const bounds = new mapboxgl.LngLatBounds();
                if (geojson.type === 'FeatureCollection') {
                    geojson.features.forEach((feature) => {
                        bounds.extend(feature.geometry.coordinates);
                    });
                } else {
                    geojson.geometry.coordinates[0].forEach((coordinate) => {
                        bounds.extend(coordinate);
                    });
                }
                map.fitBounds(bounds, {
                    padding: 100
                });

                // map.scrollZoom.disable();
                map.addControl(new mapboxgl.NavigationControl());

                ready.value = true;
            }
        }

        function flyToStore(location) {
            map.flyTo({
              center: [location.acf.location.lng, location.acf.location.lat],
              zoom: 15
            });
        }

        function createPopUp(location) {
            const popUps = document.getElementsByClassName('mapboxgl-popup');
            /** Check if there is already a popup on the map and if so, remove it */
            if (popUps[0]) popUps[0].remove();
            let popupContent = '<a target="_blank" href="';

            if (location.acf.locationLink) {
                popupContent += `${location.acf.locationLink}">`;
            } else {
                popupContent += `${location.permalink}">`;
            }

            popupContent += `<div class="location" style="background-image: url(&quot;${location.thumb.url}&quot;); display: block;">`;
            popupContent += `<h5>${location.title.rendered}</h5>`;

                if (location.acf.locationLink) {
                    popupContent += '<p><span>zur Website</span></p>';
                } else {
                    popupContent += '<p><span>Mehr erfahren</span></p>';
                }
                popupContent += '</div>';
            popupContent += '</a>';

            new mapboxgl.Popup({ offset: 35  })
              .setLngLat([location.acf.location.lng, location.acf.location.lat])
              .setHTML(popupContent)
              .addTo(map);
        }

        const locationsClick = (location) => {
            createPopUp(location);
            flyToStore(location);

            $('html,body').stop().animate({
                scrollTop: $('.locationsMap__list__container').offset().top - 80
            }, 600);
        };

        return {
            locations,
            ready,
            locationsClick,
        };
    }
};
Vue.createApp(locationsMap).mount('#locationsMap');